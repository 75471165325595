<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M0 4.93746V4.37546C0 4.15161 0.0889212 3.93694 0.247202 3.77866C0.405483 3.62038 0.620157 3.53145 0.844 3.53145H13.5V1.84445C13.4995 1.67742 13.5486 1.514 13.6411 1.37488C13.7335 1.23577 13.8652 1.12722 14.0194 1.06297C14.1736 0.998727 14.3434 0.981677 14.5072 1.01398C14.6711 1.04629 14.8217 1.1265 14.94 1.24446L17.753 4.05746C17.9111 4.21571 17.9999 4.43026 17.9999 4.65396C17.9999 4.87765 17.9111 5.0922 17.753 5.25046L14.94 8.06545C14.8217 8.18341 14.6711 8.26362 14.5072 8.29593C14.3434 8.32823 14.1736 8.31118 14.0194 8.24694C13.8652 8.18269 13.7335 8.07414 13.6411 7.93503C13.5486 7.79591 13.4995 7.63249 13.5 7.46546V5.78145H0.844C0.620157 5.78145 0.405483 5.69254 0.247202 5.53425C0.0889212 5.37597 0 5.1613 0 4.93746H0ZM17.156 10.2815H4.5V8.59445C4.50048 8.42742 4.45138 8.264 4.35892 8.12488C4.26646 7.98577 4.13481 7.87722 3.98062 7.81297C3.82643 7.74873 3.65665 7.73168 3.49276 7.76398C3.32888 7.79629 3.17827 7.8765 3.06 7.99446L0.247 10.8105C0.0889016 10.9687 9.60978e-05 11.1833 9.60978e-05 11.407C9.60978e-05 11.6307 0.0889016 11.8452 0.247 12.0035L3.06 14.8155C3.17827 14.9334 3.32888 15.0136 3.49276 15.0459C3.65665 15.0782 3.82643 15.0612 3.98062 14.9969C4.13481 14.9327 4.26646 14.8241 4.35892 14.685C4.45138 14.5459 4.50048 14.3825 4.5 14.2155V12.5315H17.156C17.2668 12.5315 17.3766 12.5096 17.479 12.4672C17.5814 12.4248 17.6744 12.3626 17.7528 12.2843C17.8312 12.2059 17.8933 12.1128 17.9358 12.0104C17.9782 11.908 18 11.7983 18 11.6875V11.1235C17.9995 10.9 17.9103 10.6858 17.7521 10.5279C17.5939 10.3701 17.3795 10.2815 17.156 10.2815Z"
        :fill="fill"
    />

  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
